<template>
<div class="ma-5">
    <v-app>
            <v-toolbar-title>Reservas</v-toolbar-title>

            <v-card>
                <v-data-table 
                :headers="headers" 
                :items="items" 
                mobile-breakpoint="800"
                class="elevation-0">
                    <template v-slot:item.actions="{ item }">
                        <div class="text-truncate">
                          <v-icon
                            small
                            class="mr-2"
                            @click="showEditDialog(item)"
                            color="primary" 
                          >
                            mdi-pencil
                          </v-icon>
                          <v-icon
                            small
                            @click="deleteItem(item)"
                            color="pink" 
                          >
                            mdi-delete
                          </v-icon>
                          <v-icon v-if="item.idTiporeserva==3"
                            small
                            class="mr-2"
                            @click="showGifEditDialog(item)"
                            color="primary" 
                          >
                            mdi-pencil
                          </v-icon>
                      </div>
                    </template>

                </v-data-table>
                <!-- this dialog is used for both create and update -->
                <v-dialog v-model="dialog" max-width="500px">
      
                  <v-card>
                    <v-card-title>
                        <span >Edit {{editedItem.id}}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="2">
                            <v-text-field disabled solo v-model="editedItem.id" label="id"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field disabled solo v-model="editedItem.fecha" label="Fecha"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field disabled solo v-model="editedItem.inicio" label="Hora"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12">
                            <v-text-field disabled solo v-model="editedItem.servicio" label="Servicio"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12">
                            <v-text-field disabled solo v-model="editedItem.nombre" label="Nombre"></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="8">
                            <v-text-field disabled solo v-model="editedItem.email" label="Valor"></v-text-field>
                          </v-col >                     
                          <v-col cols="12" sm="4">
                            <v-text-field disabled solo v-model="editedItem.precio" label="Valor"></v-text-field>
                          </v-col >
                          <v-col cols="12" sm="4">
                            <v-text-field solo v-model="editedItem.abono" label="Valor"></v-text-field>
                          </v-col >

                          <v-col cols="12" sm="8">
                            <v-select
                            :items="estados"
                            v-model="estado"
                            name="selec estado"
                            item-text="estado"
                            item-value="id"
                            label="Seleccione el estado"
                            />
                          </v-col >
                                                
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="showEditDialog()">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="saveItem(editedItem)">Save</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogGif" max-width="500px">
      
                  <v-card>
                    <v-card-title>
                        <span>Editar GifCard {{editedGifItem.id}}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-text-field solo v-model="editedGifItem.nombreGif" label="Nombre"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field solo v-model="editedGifItem.emailGif" label="Email"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field solo v-model="editedGifItem.telefonoGif" label="Telefono"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field solo v-model="editedGifItem.notaGif" label="Nota"></v-text-field>
                          </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="showGifEditDialog()">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="saveItemGif(editedGifItem)">Save</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
            </v-card>


    </v-app>
    
</div>

  
</template>

<script>

export default({
  data: () => ({
      dialog: false,
      dialogGif: false,
      dialogDelete: false,
      headers: [
          { text: 'ID', value: 'id' },
        {
          text: 'Servicio',
          align: 'start',
          sortable: false,
          value: 'servicio',
        },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Inicio', value: 'inicio' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Telefono', value: 'telefono' },
        { text: 'Email', value: 'email' },
        { text: 'Valor', value: 'precio' },
        { text: 'Abono', value: 'abono' },
        { text: 'Estado', value: 'get_estadoreserva.estado' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      items: [],
      estados: [],
      estado: "",
      categorias: [],
      editedIndex: -1,
      editedItem: {
        id: '',
        fecha: '',
        inicio: '',
        servicio: '',
        nombre:'', 
        abono: '',
        total: '',
        estado: ''
      },
      editedGifItem: {
        nombreGif:'', 
        emailGif:'', 
        telefonoGif:'', 
        notaGif:'', 
      },
      defaultItem: {
        titulo: '',
        descripcion: '',
      },
    }),
  mounted() {
    this.cargarEstadoReservas()
    this.loadItems()
  },
  methods: {
    showEditDialog(item) {
        this.editedItem = item||{}
        this.dialog = !this.dialog
    },
    showGifEditDialog(item) {
        this.editedGifItem = item||{}
        this.dialogGif = !this.dialogGif
    },
    async cargarEstadoReservas() {
        this.estados = []
        try {
          const response = await this.axios.get("/estadoreserva");
          this.estados = response.data;
        } catch (error) {
          console.log(error);
        }
    },
    async loadItems() {
        this.items = []
        try {
          const response = await this.axios.get("/reserva");
          this.items = response.data;
        } catch (error) {
          console.log(error);
        }
    },
    saveItem() {
      this.axios.put("/reserva/"+this.editedItem.id, {
          idEstadoreserva: this.estado,
          abono: this.editedItem.abono
        })
        .then((response) => {
            this.loadItems();
            this.dialog = !this.dialog
        })
    },
    saveItemGif() {
      this.axios.put("/reserva/gifCard/"+this.editedGifItem.id, {
          nombreGif: this.editedGifItem.nombreGif,
          emailGif: this.editedGifItem.emailGif,
          telefonoGif: this.editedGifItem.telefonoGif,
          notaGif: this.editedGifItem.notaGif,
        })
        .then((response) => {
            this.loadItems();
            this.dialogGif = !this.dialogGif
        })
    },
    deleteItem(item) {
        this.axios.delete("/reserva/"+item.id)
        .then((response) => {
            this.loadItems();
            
        })
    },
  }
})
</script>